.detail {
  display:none;
}
.detailText{
  color:var(--light-gray);
}
.detailText h1{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 20px;
  border-bottom: 1px solid var(--lightest-color);
  text-transform: uppercase;
}
.detailImg{
  border-radius: 4px;
  margin-top:10px;
}
.detailBody p {
  color:var(--light-gray);
  font-size: 14px;
}

.detailLink {
  font-size: 16px;
}

.video-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 
}

.video-container iframe, .video-container object, .video-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}
