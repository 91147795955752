.card {
  background-color:var(--dark-color);
  font-family: 'Noto Sans JP', sans-serif;
  border-radius: 0px;
  border:0px;
  margin-top:20px;
}

.card-body {
  padding: 20px;
  background-color:var(--darkest-color);
  border: 0px;
  border-radius: 0px;
  font-size: 14px;
  min-height: 240px;
}

.card-body .iconBox {
  height:60px;
  margin-top:-30px;
}

.card-body .iconImg {
  height: 50px;
  width: 50px;
  float: left;
  border-radius: 50%;
  border:4px solid var(--lighter-color);
  overflow: hidden;
  background-color: var(--lighter-color);
}

.card-title {
  color:var(--light-gray);
}

.card-subtitle {
  color:var(--dark-gray);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightest-color);
}

.card-text {
  color: var(--dark-gray);
}

.card-footer, 
.card-footer:last-child {
  background-color:var(--darkest-color);
  border: 0px;
  border-radius: 0px;
  padding:20px;
}

.card-link {
  float:right;
}