.headImg {
  height: 100px;
  width: 100px;
  float: center;
  border-radius: 50%;
  border:6px solid var(--lighter-color);
  overflow: hidden;
  background-color: var(--darkest-color);
  margin-bottom:20px;
}

.headBox {
  display: flex;
  justify-content: center;
}