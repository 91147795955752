
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
  overflow-y: scroll;
  scrollbar-width: thin;
}

body {
  margin: 0;
  background-color: var(--bg-color);
  padding: 1rem;
  margin: 30px auto;
  color: var(--lighter-color);
  max-width: 1200px;
}

.animate {
  animation-duration: 0.3s;
  animation-name: animate-fade;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

pre {
  white-space: normal;
}

a {
  color: var(--lightest-color);
  text-decoration: none;
}
