.grid {
  display:none;
}

.active {
  display:block
}
.inventory {
  color:var(--dark-color);
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 14px;
  text-align: center;
  text-justify: inter-word;
  margin-top: 6px;
}

.resumeLinkCol {
  height:35px;
  padding:4px;
  background-color:var(--darkest-color);
  text-align: center;
}
.resumeLink .card-link {
  text-align: center;
}
.card-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.contactInfo {
  margin-top:14px;
  margin-bottom:14px;
  text-align: center;
}

.contactInfo p {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  margin:0px;
}
.contactInfo p a {
  text-decoration: none;
}

.gitIcon {
  margin-top:0px;
  display:block;
  margin:auto;
  margin-bottom:20px;
}

.link-button {
  text-align:center;
  display:flex;
  margin:0 auto;
  background: none!important;
  border: none;
  padding: 0 0 20px 0!important;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  color:var(--lightest-color);
  text-decoration: none;
  cursor: pointer;
}
.link-button:hover {
  color:var(--bs-link-hover-color);
}

.themeIcon {
  padding-right:6px;
}

@media only screen and (min-width: 1200px) {
  .link-button {
    float: right;
    background: none!important;
    border: none;
    padding: 0!important;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    color:var(--lightest-color);
    text-decoration: none;
    cursor: pointer;
  }
  .gitIcon {
    margin-top:14px;
    float:right;
  }
  .inventory {
    font-size: 24px;
    margin-top: 0px;
  }
  .contactInfo {
    text-align: left;
  }
}
